#tech-companies-1 th {
  font-size: 16px;
  color: rgb(51, 51, 51);
}

.table_top {
  /* padding: 1px 1% 0; */
  border-bottom: 1px #ccc solid;
  margin-bottom: 15px;
  color: #424875;
}

.table_top i:hover {
  cursor: pointer;
}

.table_top>h3 {
  display: inline-block;
}

.download-excel {
  float: right;
  margin-right: 13px;
  font-size: 18px;
  color: #3c7b00;
  /* margin-top: 2px; */
  /* margin-left: 60px; */
  display: block;
  /* width: 70px; */
}

.total-count-class {
  float: right !important;
  margin-top: 3px !important;
  /* font-size: large !important; */
}

.refreshIcon i {
  display: inline-block;
  /* width: 35px;
    height: 35px;
    line-height: 31px; */
  width: 39px;
  height: 39px;
  line-height: 36px;
  font-size: 20px;
  /* color: #74788d; */
  color: #fff;
  /* border: 2px solid #f1f5f7; */
  border: 2px solid #fcb92c;
  border-radius: 4px;
  transition: all 0.4s;
  text-align: center;
  margin-right: 16px;
  vertical-align: middle;
  margin-top: 24px;
  background-color: #fcb92c;
}

.refreshIcon i:hover {
  cursor: pointer;
}

.icon-table {
  cursor: pointer;
}

#infoIcon {
  margin-right: 5px;
}

.icon-table:hover {
  color: #fcb92c;
}

.customDateOverlay {
  width: auto;
  position: absolute;
  z-index: 9999;
  border: #fcb92c 1px solid !important;
  border-radius: 4px;
}