.react-datepicker__day--selected {
    background-color: #FCB92C !important;
}

.btn-text {
    margin: 0;
    font-family: var(--bs-font-sans-serif);
    font-size: 0.9rem;
    font-weight: 400;
    line-height: 1.5;
    color: #505d69;
    background-color: #f1f5f7;
    -webkit-text-size-adjust: 100%;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

.customDateOverlay {
    width: auto;
    position: absolute;
    z-index: 9999;
    border: #fcb92c 1px solid !important;
    border-radius: 4px;
    background-color: #f1f5f7;
    padding: 20px;
}

/* CSS */
.button-6 {
    align-items: center;
    background-color: #ffffff;
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: .25rem;
    box-shadow: rgba(0, 0, 0, 0.02) 0 1px 3px 0;
    box-sizing: border-box;
    color: rgba(0, 0, 0, 0.85);
    cursor: pointer;
    display: inline-flex;
    font-family: system-ui, -apple-system, system-ui, "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-size: 16px;
    font-weight: 600;
    justify-content: center;
    line-height: 1.25;
    margin: 0;
    min-height: 3rem;
    padding: calc(.875rem - 1px) calc(1.5rem - 1px);
    position: relative;
    text-decoration: none;
    transition: all 250ms;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    vertical-align: baseline;
    width: auto;
}

.button-6:hover,
.button-6:focus {
    border-color: rgba(0, 0, 0, 0.15);
    box-shadow: rgba(0, 0, 0, 0.1) 0 4px 12px;
    color: rgba(0, 0, 0, 0.65);
}

.button-6:hover {
    transform: translateY(-1px);
}

.button-6:active {
    background-color: #F0F0F1;
    border-color: rgba(0, 0, 0, 0.15);
    box-shadow: rgba(0, 0, 0, 0.06) 0 2px 4px;
    color: rgba(0, 0, 0, 0.65);
    transform: translateY(0);
}

.margin-right {
    margin-right: 10px;
}

.margin-top {
    margin-top: 28px;
}

.align-bottom-div {
    position: relative;
    /* min-height: 150px; */
}

.align-bottom-div-content {
    position: absolute;
    bottom: 0;
    /* left: 0; */
}

.spinner-small-size {
    height: 4px;
    width: 4px;
}

.advance-filter-btn {
    display: flex;
    align-items: center;
    background-color: white;
    border: none;
    color: #505D69;
    padding: 0.75rem 0.75rem 0.75rem 0.75rem;
    text-align: center;
    text-decoration: none;
    font-size: 14px;
    /* margin: 4px 2px; */
    cursor: pointer;
    border: 1px solid #ced4da;
    /* border-color: #505D69; */
    border-radius: .25rem;
    height: 2.5rem;
    align-content: center;
    align-items: center;
    text-align: center;
    vertical-align: middle;
}

.icon-red {
    color: red;
}

.icon-green {
    color: green
}