.react-datepicker__day--selected {
  background-color: #fcb92c !important;
}

.img-modal {
  height: 190px;
  width: 190px;
  border-radius: 5px;
}

.icon-size {
  height: 10px;
  width: 10px;
  border-radius: 5px;
}

.pan-modal {
  height: 125px;
  width: 175px;
  border-radius: 5px;
  justify-content: center;
}

.icon {
  color: red;
  font-size: 30px;
  cursor: pointer;
  /* height: 20px; */
  /* justify-items: center; */
}

.icon-yellow {
  color: #fcb92c;
  font-size: 20px;
}

body {
  background-color: white;
}

.tab-panel__header {
  font-family: "Trebuchet MS";
  font-size: 12px;
  position: relative;
  top: 1px;
}

.tab-panel__content {
  background-color: whitesmoke;
  border: 1px solid whitesmoke;
  height: auto;
  padding: 20px 10px 0px 10px;
  border-radius: 5px;
  /* padding-top: 10px; */
}

.tab {
  background-color: white;
  /* border: 1px solid #9099A2; */
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  color: #767676;
  cursor: pointer;
  display: inline-block;
  padding: 6px 24px;
  position: relative;
  text-transform: uppercase;
  z-index: 0;
  font-style: normal;
  font-family: "Courier New", Courier, monospace, Trebuchet MS;
}

.tab:nth-child(n + 2) {
  margin-left: -8px;
}

.tab:hover {
  color: #767676;
}

.tab--selected {
  background-color: whitesmoke;
  /* border-bottom: 1px solid #D5D5D5; */
  color: #767676;
  font-size: 14px;
  font-weight: bold;
  padding-top: 6px;
  z-index: 1;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 9999;
  display: flex;
  justify-content: center;
  align-items: center;
}

.content {
  background-color: white;
  padding: 20px;
  border-radius: 5px;
}

.date-container {
  cursor: pointer;
}

.date-btn {
  background-color: white;
  border: 0px solid #ced4da;
  border-radius: 0.25rem;
  height: 2.5rem;
  width: 100px;
  display: flex;
  align-items: center;
  padding: 0.75rem;
  text-align: left;
  color: #505d69;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.2);
  cursor: pointer;
  text-align: center;
  justify-content: center;
}

.date-btn-close {
  background-color: #ff3d60;
  border: 0px solid #ced4da;
  border-radius: 0.25rem;
  height: 2.5rem;
  width: 100px;
  display: flex;
  align-items: center;
  padding: 0.75rem;
  text-align: left;
  color: white;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.2);
  cursor: pointer;
  text-align: center;
  align-items: center;
  align-content: center;
  justify-content: center;
}

.input-group-text-1 {
  width: 70px;
  text-align: center;
  justify-content: center;
}

.modal-card {
  width: 100%;
  height: 100%;
  border-radius: 0px;
  background-color: white;
  padding: 10px;
}

.smaller-font {
  font-size: 10px;
  /* Adjust the font size as per your requirements */
}

.header-cell {
  font-size: 10px;
  /* Adjust the header font size as per your requirements */
  /* Add any additional styling properties for the header cells */
}

.margin-right {
  margin-right: 10px;
}

