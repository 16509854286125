.customDateOverlay {
    width: auto;
    position: absolute;
    z-index: 9999;
    border: #fcb92c 1px solid !important;
    border-radius: 4px;
}

.dateRangeWrapper {
    border: 5px solid lightslategray !important;
}

.image-container {
    width: 300px; /* Set the width of the container to your desired size */
    height: 200px; /* Set the height of the container to your desired size */
    overflow: hidden; /* Hide any image overflow outside the container */
  
    /* Optional: You can center the images both horizontally and vertically */
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .image-container img {
    max-width: 100%; /* Ensure images don't exceed the width of the container */
    max-height: 100%; /* Ensure images don't exceed the height of the container */
    height: auto; /* Maintain the aspect ratio of the images */
    width: auto; /* Maintain the aspect ratio of the images */
  }
  .label {
    display: block;
  }
  .card-text-scroll {
    max-height: 100px; /* Set a maximum height for the scrollable area */
    overflow-y: auto; /* Enable vertical scrolling if content overflows */
}