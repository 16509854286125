/* style the multiselect container */
.react-select-container {
    width: 300px;
}

/* style the control container */
.react-select__control {
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 16px;
    font-weight: 400;
    height: 40px;
}

/* style the placeholder text */
.react-select__placeholder {
    /* color: ; */
    opacity: calc(10%);
}

/* style the options container */
.react-select__menu {
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 16px;
    font-weight: 400;
}

/* style the options list */
.react-select__menu-list {
    max-height: 200px;
}

/* style the options */
.react-select__option {
    padding: 8px;
    cursor: pointer;
}

/* style the selected option */
.react-select__multi-value {
    background-color: #ccc;
    color: #fff;
    border-radius: 4px;
    margin-right: 4px;
    padding: 4px 8px;
}

/* style the remove button */
.react-select__multi-value__remove {
    cursor: pointer;
    margin-left: 4px;
}

/* style the input field */
.react-select__input {
    padding: 0;
    font-size: 16px;
    font-weight: 400;
}

/* style the dropdown indicator */
.react-select__dropdown-indicator {
    color: yellow;
}

/* style the control container */
.react-select__control {
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 16px;
    font-weight: 400;
    height: 40px;
}

/* style the control container when focused */
.react-select__control:focus {
    border-color: yellow;
}