.button {
  /* display: inline-block; */
  padding: 0 25px;
  width:auto;
  height: 30px;
  font-size: 14px;
  line-height: 30px;
  border-radius: 25px;
  border-color: #FCB92C;
  /* color:#FCB92C; */
  background-color: white;
  margin: 0 0px 0px 0;
  /* cursor: pointer; */
  /* transition: background-color 0.2s; */
}