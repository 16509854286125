.pe {}

.pe:hover {
    box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
    cursor: pointer;
}

.vertical-line {

    border-right: 1px solid lightgray;
    height: auto;
    align-items: center;
    /* set the height of the line */

}

.cd-border {
    border: 2px solid #FCB92C !important;
    border-radius: 5px;
    /* padding: 10px; */
    /* margin: 10px; */
}

.list {
    /* Hide the list by default */
    display: none;
    position: absolute;
    top: 100%;
    /* Position the list below the card */
    left: 0;
    background-color: #05635e;
    padding: 10px;
    list-style: none;
    border-radius: 4px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
    z-index: 1;
    color: aliceblue;
}

.card:hover .list {
    /* Display the list on card hover */
    display: block;
    opacity: 1;
    animation-name: fade-in;
    animation-duration: 0.3s;
}

@keyframes fade-in {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}