.box-selection-wrap {
  // max-width: 600px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin: 0 auto;
}
.box-selection-item {
  border: 1px solid var(--bs-yellow);
  border-radius: var(--bs-card-border-radius);
  width: 150px;
  height: 150px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 8px;
  text-align: center;
  cursor: pointer;
  background: #ffffff;
  margin: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.08);
  img {
    width: 44px;
    margin-bottom: 16px;
  }
  &:hover {
    background: var(--bs-yellow);
    box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
  }
  .box-inner-title {
    font-size: 20px;
    font-weight: 600;
    color: var(--bs-card-title-color);
    margin-bottom: 0;
    line-height: 1.2;
  }
  
  .box-inner-subtitle {
    font-size: 16px;
    font-weight: 600;
    color: var(--bs-card-title-color);
    margin-bottom: 0;
    line-height: 1.2;
  }
}

// validation
.form-row {
  .form-label {
    margin-bottom: 0.2rem;
  }
  .form-error {
    color: #dc3545;
    margin-top: 0.2rem;
    font-size: 14px;
  } 
  .input-group {
    .form-control {
      z-index: 0 !important;
    }
  }
  .select-group {
    [class*='-control'] {
      border-color: #b1bbc4;
      box-shadow: none;
      &:focus {
        border-color: #b1bbc4;
      }
    }
  }
  .input-error {
    &.select-group {
      [class*='-control'] {
        border-color: #dc3545;
        box-shadow: none;
        &:focus {
          box-shadow: 0 0 0 0.15rem rgba(255, 61, 96, 0.25);
        }
      }
    }
  }
}