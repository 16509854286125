.card-list {
  display: flex;
  flex-wrap: wrap;
}

.c {
  width: 400px;
  /* height: 200px; */
  margin: 0px 0px 10px 0px;
  /* border: 1px solid rgb(156, 231, 196); */
  /* box-shadow: 12px 17px 51px rgba(0, 0, 0, 0.22); */
  /* backdrop-filter: blur(6px); */
  /* border-radius: 17px; */
}

/* .c:hover {
    border: 1px solid rgb(113, 225, 113);
    transform: scale(1.05);
  } */

.card-header {
  border-radius: 4px;
}

.card-width {
  border: 2px solid red;
  display: grid;
  grid-template-columns: 1fr minmax(auto, 20%);
}

.card-success {
  background-color: #9f9 !important;
  color: #9f9 !important;
}

.containerCard {
  display: flex;
  align-items: center;
  /* Vertically align the text */
}

.text {
  text-align: left;
  /* Horizontally align the text */
  white-space: nowrap;
  /* Prevent the text from wrapping */
  overflow: hidden;
  /* Hide the overflowed text */
  text-overflow: ellipsis;
  /* Add ellipsis to indicate hidden text */
  flex: 1;
  /* Expand to fill the available space */
}

.rounded-circle {
  border-radius: 50%;
  border: solid 2px rgb(255, 255, 255);
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));

}

.card-header-br {
  border-radius: 25px 25px 25px 25px;
  height: 40px;
  align-items: center;
  /* margin-top: 10px; */
  /* margin-bottom: 10px; */
  margin: 10px 0px 10px 0px;
}

.ic-bg {
  background-color: #fff;
  border-radius: 50%;
  padding: 2px;
  margin-right: 10px;
  width: 20px;
  align-items: center;
  align-content: center;
}


.container-green {
  background: radial-gradient(circle, #59EBB5, #20C5AF);
}

.container-red {
  background: radial-gradient(circle, #AB0000, #790102);
}

.container-dark {
  background: radial-gradient(circle, #A9ADB0, #4b4b4b);
}

.card-radius {
  border-radius: 18px 18px 18px 18px !important;
}

.horizontal-list-card {
  /* Use flexbox layout */
  flex-wrap: wrap;
  /* Wrap items to new line when they exceed container width */
  list-style: none;
  /* Remove default bullet points */
  padding: 0;
  margin: 0;
}

.horizontal-list li {
  flex: 1 0 auto;
  /* Grow and shrink items to fill the container */
  text-align: center;
  /* Center the text inside the item */
}